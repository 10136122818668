import { findLast, isFinite, sortBy, omit, cloneDeep } from 'lodash-es';
import getUnixTime from 'date-fns/getUnixTime';
import isSameDay from 'date-fns/isSameDay';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import { isActivityplanInSchoolyear } from '@utils/utils';
import addDays from 'date-fns/addDays';
import { addHolidays, generateCalendarWeeksForPlans, getHolidays } from '@utils/dateHelpers';
import { fillExpandedClasses, sortByClassName, sortByDisplayName } from './calendarHelper';
import { DEFAULT_COLORS, EMPTY_ACTIVITY, HOLIDAYS } from './calendarConsts';

export function makeActivityDates(activities) {
  return activities.map((activity) => {
    const dtStartDate = new Date(activity.period.startDate);
    const dtEndDate = new Date(activity.period.endDate);
    return {
      ...activity,
      period: {
        ...activity.period,
        dtStartDate,
        dtEndDate,
        interval: { start: dtStartDate, end: dtEndDate },
        unixStartDate: getUnixTime(dtStartDate),
        unixEndDate: getUnixTime(dtEndDate),
      },
    };
  });
}

function getRootActivities(activities) {
  return activities.filter((activity) => activity.parent.href.indexOf('/activities/') === -1);
}

function getChildActivities(activities, parentKey) {
  return activities.filter((activity) => activity.parent.href.indexOf(parentKey) !== -1);
}

export function getColorForClasses(classes) {
  const colorMap = {};

  classes.forEach((clss, index) => {
    const colorIndex = index >= DEFAULT_COLORS.length ? index % DEFAULT_COLORS.length : index;
    colorMap[clss.href] = DEFAULT_COLORS[colorIndex];
  });
  return colorMap;
}

function makeHashCounts(plans, hashfn) {
  const hashCounts = {};
  const hashList = {};
  plans.forEach((plan) => {
    const roots = getRootActivities(plan.activities);

    roots.forEach((act) => {
      let hash = hashfn(act);
      let children = getChildActivities(plan.activities, act.key);
      // //sort by startDate should be enough for child elements. there is no overlap here.
      children = sortBy(children, [(o) => o.period.unixStartDate]);

      children.forEach((child) => {
        child.hashString = hashfn(child);
        hash += child.hashString;
      });

      act.children = children; // /store the links in the root object.
      act.hashString = hash; // store the hash

      hashCounts[hash] = hashCounts[hash] ? hashCounts[hash] + 1 : 1;

      if (hashList[act.hashString]) {
        hashList[act.hashString].push(act);
      } else {
        hashList[act.hashString] = [act];
      }
    });
  });

  return hashList;
}

function removeItemsByHash(activities, hashes) {
  const removedParents = getRootActivities(activities).filter((act) =>
    hashes.includes(act.hashString)
  );
  const hrefs = removedParents.reduce(
    (acc, parent) => [
      ...acc,
      parent.$$meta.permalink,
      ...getChildActivities(activities, parent.key).map((c) => c.$$meta.permalink),
    ],
    []
  );

  return activities.filter((act) => !hrefs.includes(act.$$meta.permalink));
}

function mergePlans(plans, hashesObject) {
  const mainPlan = { ...plans[0] };
  mainPlan.activities = [];
  mainPlan.color = mainPlan.planGroup.color;
  // mainPlan.mergedFrom = plans.map((p) => p.$$meta.permalink);
  mainPlan.classes = plans.map((p) => p.class.href);

  Object.keys(hashesObject).forEach((hash) => {
    // /TODO MAYBE we dont need the if check
    if (hashesObject[hash].length > 1) {
      // the hash has values from multiple plans. we got work to do.
      const items = hashesObject[hash];
      // add first item into the mainplan. add all items in mergedItems.
      // do the same for the children.
      const itemForMainplan = cloneDeep(items[0]);

      itemForMainplan.children.forEach((child, index) => {
        // /each child has to have its merged cousins too. the children of each parent are sorted. so if the hash of the parent is the same. it means the hash of each first child is the same.
        // /here we add the child mergedItems.
        child.mergedItems = [];
        items.forEach((parent) => {
          child.mergedItems.push({ ...parent.children[index] });
        });
        mainPlan.activities.push(child);
      });

      items.map((item) => {
        return omit(item, ['children']);
      }); // forget about the children. we don't need them anymore.

      itemForMainplan.mergedItems = items;

      mainPlan.activities.push(itemForMainplan);
    }
  });

  // /remove all items from mainplan in all other plans.
  plans.forEach((plan) => {
    plan.activities = removeItemsByHash(
      plan.activities,
      mainPlan.activities.map((e) => e.hashString)
    );
    plan.classes = [plan.class.href];
  });

  const leftOverPlans = [mainPlan, ...plans].filter((plan) => plan.activities.length > 0);

  // if (mainPlan.activities.length > 0) {
  //   service.plans.push(mainPlan);
  // }

  const x = leftOverPlans.length === 0 ? [mainPlan] : leftOverPlans; // /in case of a new calendar, none of the plans will have anything. so we just return mainplan.
  return x;
}

function countOccurencePerGoal(plans) {
  const hashGoals = new Map();

  plans.forEach((plan) => {
    plan.activities.forEach((act) => {
      const goalSet = hashGoals.get(act.hashString) || new Set();
      if (act.goals)
        act.goals.forEach((goal) => {
          goalSet.add(goal.href);
        });
      hashGoals.set(act.hashString, goalSet);
    });
  });

  const goalCounts = new Map();

  hashGoals.forEach((value) => {
    [...value].forEach((goalHref) => {
      let count = goalCounts.get(goalHref) || 0;
      count += 1;
      goalCounts.set(goalHref, count);
    });
  });

  return goalCounts;
}

function positionActivities(plans) {
  plans.forEach((plan) => {
    let minPosition = 0;
    let maxPosThisPlan = 0;
    const roots = getRootActivities(plan.activities);

    const sortedRoots = roots.sort((a, b) => {
      const res = a.period.dtStartDate - b.period.dtStartDate;
      if (res === 0) {
        // in case of equal startdate, sort by enddate in reverse, longer events go higher up
        return b.period.dtEndDate - a.period.dtEndDate;
      }
      return res;
    });

    // they are now sorted, now they need to get a number.
    // the number is the previous parent's number + 1 in case of overlap
    // in case of overlap or no overlap, also have to check all previuous parents for overlap, to come up with a number.
    for (let i = 0; i < sortedRoots.length; i += 1) {
      const curItem = sortedRoots[i];
      let position = minPosition; // set the default.

      while (
        sortedRoots.some(
          // eslint-disable-next-line no-loop-func
          (e) =>
            e.position === position &&
            areIntervalsOverlapping(e.period.interval, curItem.period.interval)
        ) &&
        position < 100
      ) {
        // because 100 layers is insane
        position += 1;
      }

      curItem.position = position;
      maxPosThisPlan = maxPosThisPlan < position ? position : maxPosThisPlan;
    }

    minPosition = maxPosThisPlan + 1;
  });

  return plans;
}

function colorize(plans) {
  plans.forEach((plan) => {
    const color =
      plan.classes?.length > 1 ? { background: '#E0E0E0', text: '#9B009B' } : plan.class.color;
    const roots = getRootActivities(plan.activities);
    roots.forEach((activity) => {
      activity.$$color = color;
    });
  });

  return plans;
}

function setClassesName(activities, allSelectedClasses) {
  const classes = allSelectedClasses
    .filter((c) => c.selected || c.selected === undefined)
    .sort(sortByDisplayName)
    .map((p) => p.$$displayName)
    .join(' + ');
  const classesName = activities
    .map((a) => a.$$class)
    .filter((a) => a)
    .sort(sortByDisplayName)
    .map((p) => p.$$displayName)
    .join(' + ');
  if (classes === classesName) return '';
  return classesName;
}

function setArrowPosition(activity, parent) {
  const parentPeriod = parent.period;
  const child = activity.period;

  if (isSameDay(child.dtStartDate, parentPeriod.dtStartDate)) {
    if (isSameDay(child.dtEndDate, parentPeriod.dtEndDate)) return 'SINGLE';
    return 'START';
  }
  if (isSameDay(child.dtEndDate, parentPeriod.dtEndDate)) {
    return 'END';
  }
  return 'MIDDLE';
}

function makeActivityGroup(activity, parent, allSelectedClasses) {
  const activities = activity.mergedItems ? activity.mergedItems : [activity];
  return {
    id: activity.key,
    $$classesName: setClassesName(activities, allSelectedClasses),
    title: parent.title,
    description: activity.description,
    color: parent.$$color,
    attachments: activity.$$attachments || [],
    goals: [...parent.goals, ...activity.goals],
    position: parent.position,
    arrowState: setArrowPosition(activity, parent),
    editable: false,
    dragging: false,
    activities_del: activities, // todo: make it so that we don't need this.
    parentActivities: activities.map((e) => e.parent.href),
  };
}

function addActivityPlan(weeks, plan, allSelectedClasses) {
  const activitiesMap = new Map(plan.activities.map((a) => [a.$$meta.permalink, a]));
  const activities = getChildActivities(plan.activities, '/activities/');
  // const parents = getRootActivities(plan.activities);

  weeks.forEach((week) => {
    const activitiesPerWeek = activities.filter((activity) =>
      isSameDay(week.startDate, activity.period.dtStartDate)
    );

    const activityPlan = {
      ...omit(plan, [
        'activities',
        '$$meta',
        'context',
        'creators',
        'curricula',
        'issued',
        'observers',
        'softDeleted',
        'title',
        'class',
        'key',
        'activityplangroup',
        'planGroup',
        'color',
      ]),
      activityGroups: activitiesPerWeek.map((activity) =>
        makeActivityGroup(activity, activitiesMap.get(activity.parent.href), allSelectedClasses)
      ),
    };

    week.activityPlans ??= [];
    week.activityPlans.push(activityPlan);
  });

  return weeks;
}

function fillEmptyGaps(weeks) {
  let usedNumbers = new Set();

  weeks.forEach((week) => {
    week.activityPlans.forEach((plan) => {
      const numbers = plan.activityGroups.map((e) => e.position);
      usedNumbers = new Set([...usedNumbers, ...numbers]);

      const max = Math.max(...numbers);
      const fakeMax = isFinite(max) ? max : 0;

      for (let i = 0; i <= fakeMax; i += 1) {
        if (
          (!plan.activityGroups.some((e) => e.position === i) && usedNumbers.has(i)) ||
          !isFinite(max)
        ) {
          const empt = cloneDeep(EMPTY_ACTIVITY);
          empt.position = i;
          plan.activityGroups.push(empt);
        }
      }
    });
  });
}

function sortPositions(weeks) {
  weeks.forEach((week) => {
    week.activityPlans.forEach((pg) => {
      pg.activityGroups = sortBy(pg.activityGroups, 'position');
    });
  });
}

function canMoveBack(weeks) {
  weeks.forEach((week) => {
    week.activityPlans.forEach((ap) => {
      const firstNotEmpty = findLast(ap.activityGroups, (item) => !item.empty);
      ap.canMoveBack = !(
        firstNotEmpty &&
        (firstNotEmpty.arrowState === 'MIDDLE' || firstNotEmpty.arrowState === 'END')
      );
    });
  });
}

export function hasher(activity) {
  let hash = `${activity.period.unixStartDate}${activity.period.unixEndDate}${activity.title}~#~${activity.description}`;

  if (activity.goals && activity.goals.length > 0) {
    hash += activity.goals
      .map((e) => e.href)
      .sort()
      .join();
  }

  return hash;
}

function getCurriculaName(currs) {
  const title = currs.map((e) => e.name + (e.versionNumber ? ` (${e.versionNumber})` : ''));

  return title.length > 0 ? title.join(', ') : '';
}

export function getPlanName(planGroup, currs) {
  if (planGroup.title) return planGroup.title;
  return getCurriculaName(currs);
}

export function getPlanSubtitle(planGroup, currs) {
  if (!planGroup?.title) return '';
  return getCurriculaName(currs);
}

export function getTeachersName(planGroup, allTeachers) {
  const teachers = planGroup.creators
    .map((e) => {
      const teach = allTeachers.find((t) => t.$$meta.permalink === e.href);
      return teach ? teach.$$displayName : null;
    })
    .filter((e) => e);
  return teachers.join(', ');
}

export function getSchoolyearForPlan(plan, schoolyears) {
  return schoolyears.find((e) =>
    isActivityplanInSchoolyear(plan.issued.startDate, plan.issued.endDate, e)
  );
}

export function fillGoals(hrefs, allGoals) {
  if (!hrefs) return [];

  return hrefs
    .map((hrefObj) => {
      const goal = allGoals.find((g) => g.$$meta.permalink === hrefObj.href);
      // if (!goal) console.warn('goal not found: ', hrefObj.href);
      return goal;
    })
    .filter((e) => e);
}

export function createPlans(iplans, classes, classColors) {
  const sortedPlans = iplans
    .map((p) => fillExpandedClasses(p, classes))
    .filter((p) => p.class)
    .sort(sortByClassName)
    .map((p) => {
      return { ...p, class: { ...p.class, color: classColors[p.class.href] } };
    });

  return sortedPlans.map((plan) => {
    const activities = plan.activities.map((a) => ({
      ...a,
      $$class: plan.class,
    }));

    return {
      ...plan,
      activities,
      // color: plan.class.color,
      planGroup: {
        type: 'primary',
        color: 'grey',
      },
    };
  });
}

export function generateCalendarForPlans(plans, merge) {
  let newPlans = plans.map((plan) => {
    // const activities = addParentsToChildActivities(plan.activities);
    return { ...plan };
  });

  const allSelectedClasses = newPlans.map((e) => e.class);

  // we want the hashes, even if we don't merge. for easier reference. we use it in activity.edit.
  // this function sets .hashString in each parent and child.
  const hashesObject = makeHashCounts(newPlans, hasher);

  if (merge === true) {
    newPlans = mergePlans(newPlans, hashesObject);
  }

  const holidays = getHolidays(HOLIDAYS);
  let weeks = generateCalendarWeeksForPlans(newPlans);
  weeks = addHolidays(weeks, holidays);

  newPlans = positionActivities(newPlans);
  newPlans = colorize(newPlans);
  newPlans.forEach((plan) => {
    weeks = addActivityPlan(weeks, plan, allSelectedClasses);
  });

  fillEmptyGaps(weeks);
  sortPositions(weeks);
  canMoveBack(weeks);

  const occurencesPerGoal = countOccurencePerGoal(newPlans);

  return { weeks, occurencesPerGoal };
}

export function generateEmptyCalendar(plan) {
  const holidays = getHolidays(HOLIDAYS);
  let weeks = generateCalendarWeeksForPlans([plan]);
  weeks = addHolidays(weeks, holidays);
  weeks.forEach((week) => {
    week.activityPlans = [];
  });

  return weeks;
}

export function filterCalendarVMWeeks(calendarVM, range) {
  if (!range) return calendarVM;

  const { weeks } = calendarVM;
  const fromIndex = weeks.findIndex((w) => w.startDate.toISOString() === range.from);
  const toIndex = weeks.findIndex((w) => addDays(w.endDate, -1).toISOString() === range.to);
  const filteredWeeks = weeks.slice(fromIndex, toIndex + 1);
  return { ...calendarVM, weeks: filteredWeeks };
}
