import apisAndUrls from "./apis";
import demo from "./demo";
import oauth from "./oauth";

const settings = {
  ...demo,
  angularModuleName: "LlinkID",
  projectName: "LLinkID App",
  projectVersion: "main",
  release: "llinkid__undefined__2024-10-30T04:32:01.178Z",
  environment: "",
  projectDescription: "the LlinkID website",
  enableMswInBrowser: false, // enable Mock Service Worker
  googlAnalytics: {
    enabled: true,
    token: "G-JLQSGKFVK9",
  },
  apisAndUrls,
  logrocket: {
    enabled: false,
    key: "jr7jsu/llinkidtmp",
  },
  whatIsNewModal: {
    autoShowIfNeeded: true,
    initialDate: "2024-07-08",
    endDate: "2024-09-30",
    version: "R2405",
    showFooterWhatIsNewLink: true,
  },
  cookies: {
    contentHref:
      "https://content-api-html.katholiekonderwijs.vlaanderen/content/1caae9ab-66f2-4468-9486-abecea6ab2d0/html",
    essentialId: "4f01a62a-4520-4f9e-a233-b819c23799d7",
    analyticId: "34b19c8e-00c5-4644-89e1-910153f33bd9",
  },
  hardcodings: {
    distribution: [
      "/content/f55f8ef0-b682-4cc9-9a0a-f92c2ee4b29a", // Godsdienst - I A (v1)
      "/content/ceb1b36c-8325-48fb-b727-f746abf05aab", // Godsdienst - I B (v1)
      "/content/df909270-1057-43c6-8e0e-ad9fed7fa81c", // Godsdienst - II DAA (v1)
      "/content/d998902e-f983-4e9d-9bd1-0c473787026b", // Godsdienst - II DDA (v2)
      "/content/acfec28f-2e92-43a9-a891-2d6e1f265540", // Godsdienst - I A (v2)
      "/content/8a73f1eb-723f-4bc4-ad03-921aa4d27714", // Godsdienst - II DAA (v2)
      "/content/50213bca-663a-433a-8a8d-3e6b4e43c548", // Godsdienst - II DDA (v1)
      "/content/357c4fa6-3d96-4913-a743-db659e0f584a", // Godsdienst - I B (v2)
      "/content/0cc7bb96-750d-4ee2-9277-269617227b35", // Godsdienst - III D (v1)
    ],
    fileLimitSize: 104900000,
    initSchoolyearEarly: {
      months: 2,
    },
    endSchoolyearEarly: {
      months: 2,
      days: -1, // add one day because enddate is 31/8 not 1/9. this makes the expirydate 1st of july
    },
    markExplanation: {
      regular: [],
      highlight: ["55191f8b-a15b-4590-b282-9a232104d3b6"],
      special: ["2e775459-c0a6-413c-8f5b-96c121bea094"],
    },
  },
  cacheTimeout: {
    userIdleTime: 110,
    updateCacheEvery: 90,
    customcurAndAnnot: "5",
    updateCacheExpiredSchoolyear: 3600, // 1h
  },
  trustedOrigins: ["*"],
  logging: {
    sentry: {
      enabled: true,
      url: "https://c09cc1e062784083a5d12628b7dd06a1@o268751.ingest.sentry.io/5640652",
      performanceSampleRate: 0.2,
      replaysSessionSampleRate: 0.05,
      browserTracing: false,
    },
  },
  oauth,
  component: "/security/components/llinkid",
  asyncSaving: {
    delay: 5000,
    maxDelay: 60000,
    minDelay: 5000,
  },
  lastInitializedSchoolYear: "2024-2025",
  noSpinnerDataValidityInSeconds: 2 * 24 * 3600, // 2 days
  popup: [],
  autoStarred: "v3",
};

export default settings;
