import { useEffect, useRef } from 'react';

import DistributeModal from '@components/distribution/distributeModal/DistributeModal';
import StudyProgrammesAlert from '@components/leerplan/alert/StudyProgrammesAlert';
import NonDerivedOptionsModal from '@components/leerplan/modals/nonDerivedOptionsModal/NonDerivedOptionsModal';
import ReferenceGoalModal from '@components/leerplan/modals/referenceGoal/ReferenceGoalModal';
import RemoveSectionWarningModal from '@components/leerplan/modals/RemoveSectionWarningModal';
import WrongRouteModal from '@components/leerplan/modals/WrongRouteModal';
import {
  ScrollBottomDropTarget,
  ScrollTopDropTarget,
} from '@components/leerplan/scrollHelpers/ScrollHelpers';
import useExpandSection from '@hooks/useExpandSection';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import {
  selectCurriculaReadOnly,
  selectFullAnnotatedCurriculumViewModel,
} from '@store/curriculum/curriculumSelectors';
import { curriculumActions } from '@store/curriculum/curriculumState';
import { referenceGoal } from '@store/llinkidApis/llinkidApiState';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import { isIframe } from '@utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import CreateGoalModal from '../modals/customGoal/CustomGoalModal';
import SelectionFooter from '../selectionFooter/SelectionFooter';
import GoalList from './GoalList';
import './goalsTab.scss';

const GoalsTab = ({ selector = false }) => {
  const navigate = useNavigate();
  const data = useOutletContext(); // <{ params: CurriculumParams | undefined}>
  const expandSection = useExpandSection();
  const dispatch = useDispatch();
  const wrongRouteModalRef = useRef();
  const nonDerivedOptionsModalRef = useRef();
  const removeSectionWarningModalRef = useRef();
  const customGoalModalRef = useRef();
  const copyGoalModalRef = useRef();
  const distributeSelectionModalRef = useRef();
  const params = data?.params;

  const goalList = useSelector(
    (state) => selectFullAnnotatedCurriculumViewModel(state, params)?.doelenlijst
  );

  const isReadOnly = useSelector((state) => selectCurriculaReadOnly(state) || goalList?.locked);

  const onOpenCopyGoalModal = (parent) => {
    copyGoalModalRef.current?.open({
      parent,
      action: 'reference',
      combinedParams: params,
      onClose: async (item) => {
        if (item) {
          nonDerivedOptionsModalRef.current?.close();
          dispatch(referenceGoal(item));
          expandSection(parent);
        }
      },
    });
  };

  const onOpenEditGoalModal = (goal) => {
    customGoalModalRef.current?.open({
      isEditMode: true,
      goal,
      onClose: async (item) => {
        nonDerivedOptionsModalRef.current?.close();
        if (item) {
          console.log(item);
        }
      },
    });
  };

  const onOpenCreateGoalModal = (parent) => {
    customGoalModalRef.current?.open({
      isEditMode: false,
      parent,
      onClose: async (item) => {
        nonDerivedOptionsModalRef.current?.close();
        if (item) {
          console.log(item);
        }
      },
    });
  };

  const nonDerivedOptions = (isFirstItem, item) => {
    nonDerivedOptionsModalRef.current?.open({
      parent: item || goalList,
      combinedParams: params,
      onOpenCopyGoalModal,
      onOpenCreateGoalModal,
    });
  };

  const removeSection = (section) => {
    const [firstSection] = goalList.sections;
    const isFirstSection = firstSection ? firstSection.key === section.key : false;

    if (isFirstSection && section.sections?.length > 0) {
      removeSectionWarningModalRef.current?.open({
        title: 'Waarschuwing',
        description: `Deze titel heeft ${section.sections.length} subtitels. Gelieve de subtitels eerst te verwijderen.`,
      });
    } else {
      dispatch(curriculumActions.removeCustomSection({ section }));
    }
  };

  const scrollToAnchor = (anchorId) => {
    const anchor = document.getElementById(anchorId);
    if (!anchor) return;

    // The anchor element is inside a goal-section-content div, which can have a header as a sibling.
    // The header is a sticky element and has a variable height, so we need to calculate it and
    // subtract it from the scroll position.
    const section = anchor.closest('.react-goal-section:not(.sub-section)'); // get the sticky section that is not a sub-section
    const parentHeader = section?.getElementsByTagName('header')?.[0]; // get the header-child of the section

    let offset = 0;
    if (parentHeader) {
      offset = parentHeader.offsetHeight;
    }

    anchor.scrollIntoView(); // anchor.scrollIntoView({ block: 'center' }) would avoid the calculations part above.
    window.scrollBy(0, -offset);
  };

  useEffect(() => {
    if (params.anchor) {
      scrollToAnchor(params.anchor);
    }

    if (selector) {
      if (!isIframe()) {
        wrongRouteModalRef.current?.open({
          onClose: () => {
            dispatch(curriculumActions.setSelectionMode(false));
            navigate('/home/leerplan');
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEmptyNonderived = () =>
    !(goalList?.goals?.length || goalList?.sections?.length) &&
    params.curriculumKey === 'nonderived';

  return (
    <>
      <div className="goals-tab-background nopadding">
        <ScrollTopDropTarget />
        {isEmptyNonderived() && !isReadOnly && !selector && (
          <div className="nonderived-modal">
            <div className="modal-button" onClick={() => nonDerivedOptions(null)}>
              <div>
                <div className="add-icon">+</div>
                Invoegen
              </div>
            </div>
          </div>
        )}

        <GoalList
          goalList={goalList}
          onOpenEditGoalModal={onOpenEditGoalModal}
          nonDerivedOptions={nonDerivedOptions}
          removeSection={removeSection}
        />
      </div>

      {selector && <SelectionFooter />}
      <ScrollBottomDropTarget />

      <Modal
        ref={wrongRouteModalRef}
        size="md"
        component={<WrongRouteModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={nonDerivedOptionsModalRef}
        size="md"
        component={<NonDerivedOptionsModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={removeSectionWarningModalRef}
        size="md"
        component={<RemoveSectionWarningModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={copyGoalModalRef}
        size="lg"
        component={<ReferenceGoalModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={customGoalModalRef}
        size="lg"
        component={<CreateGoalModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <Modal
        ref={distributeSelectionModalRef}
        size="md"
        component={<DistributeModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
      <StudyProgrammesAlert
        stateParams={params}
        distributeSelectionModalRef={distributeSelectionModalRef}
      />
    </>
  );
};

export default GoalsTab;
