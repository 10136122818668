import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '@store/userAndSchool/userAndSchoolSelectors';
import { useNavigate } from 'react-router-dom';

import { logAndCaptureException } from '@utils/logAndCaptureException';
import {
  POST_MESSAGE_STATE,
  POST_MESSAGE_EVENT,
  POST_MESSAGE_ACTION,
} from '../constants/postMessageEvents';

const DEFAULT_OPTIONS = {
  autostart: true,
  trustedOrigins: ['*'],
  messageValidator: () => {},
  onError: (error, send) => {
    logAndCaptureException(error);
    send({ state: '-1', event: 'ERROR', error: error?.message });
  },
};

const getWindowOpener = () => {
  let originWindow = window.opener; // Communication between tabs
  if (!originWindow) {
    if (window !== window.parent) {
      originWindow = window.parent; // Communication with an iframe
    }
  }

  return originWindow;
};

const getFullOptions = (options = {}) => ({
  ...DEFAULT_OPTIONS,
  ...options,
});

/**
 * React custom hook to use PostMessage API.
 * @param {function | {[key: string]: function}} callback - a function or object of functions that will run when a message is recieved
 * @param {Object} options - options
 * @param {boolean} [options.autostart=true] - auto add EventListener onMount
 * @param {string[]} [options.trustedOrigins=['*']] - an array of strings that contains trusted domains
 * @param {function} options.messageValidator - a function that will run on every message to validate it
 * @param {function} options.onError - a function that will run when validator failed
 * @returns {Array.<function, function>} returns an array with the sendMessage and listener functions
 */
const usePostMessage = (callback, options = DEFAULT_OPTIONS) => {
  const listenerRef = useRef();
  const config = useMemo(() => getFullOptions(options), [options]);
  const trustedOrigins = config.trustedOrigins.join(',');

  const sendMessage = useCallback(
    (message) => {
      const opener = getWindowOpener();
      if (!opener) throw Error('[usePostMessage] No opener found');
      else {
        opener.postMessage(message, trustedOrigins);
      }
    },
    [trustedOrigins]
  );

  const recieveMessage = useCallback(
    (event) => {
      const { source, origin, data } = event;
      const isFromTrustedOrigin = trustedOrigins.includes('*') || trustedOrigins.includes(origin);
      const send = (message) => source?.postMessage(message, origin);

      // Avoid unnecessary runs
      if (data?.type?.includes('webpack') || data?.source?.includes('devtools')) return;

      if (isFromTrustedOrigin) {
        try {
          config.messageValidator?.(data);
        } catch (error) {
          config.onError?.(error, send);
        }

        if (typeof callback === 'function') {
          // if the callback is a function, we call it for every incoming message
          callback?.(data, send);
        } else if (typeof callback === 'object') {
          // if the callback is an object, we call the function that matches the action property
          callback[data.action]?.(data, send);
        }
      }
    },
    [callback, config, trustedOrigins]
  );

  const listener = useCallback(() => {
    // We keep a reference of the EventListener to clean it in case listener is called multiple times in the same component
    if (listenerRef.current) {
      window.removeEventListener('message', recieveMessage);
    }
    listenerRef.current = window.addEventListener('message', recieveMessage);

    // Unsubscribe function
    return () => {
      listenerRef.current = null;
      window.removeEventListener('message', recieveMessage);
    };
  }, [recieveMessage]);

  useEffect(() => {
    let unsubscribe;
    // We call listener to add the EventListener to the PostMessage when the hook is mounted
    if (config.autostart) unsubscribe = listener();

    // Clean up
    return () => unsubscribe?.();
  }, [listener, config.autostart]);

  return [sendMessage, listener];
};

const postMessageConfig = {
  messageValidator: (message) => {
    if (!message) throw new Error('There is no value in data property');

    if (Object.keys?.(message)?.length === 0) {
      throw new Error('Data must be an object and it must not be empty');
    }

    if (!message.action && !message.event === 'ERROR')
      throw new Error('Data has no action property');
  },
};

export const useNavigatePostMessage = () => {
  const navigate = useNavigate();

  const defaultConfig = useMemo(
    () => ({
      [POST_MESSAGE_ACTION.NAVIGATE]: (message) => {
        if (message.url) {
          if (message.url.startsWith('/')) {
            navigate(message.url);
          } else {
            throw new Error('Only relative urls are allowed, starting with /');
          }
        } else {
          throw new Error('No url provided in the message');
        }
      },
    }),
    [navigate]
  );

  return usePostMessage(defaultConfig, postMessageConfig);
};

export const useLlinkidPostMessage = (callback) => {
  const [sendMessage, listener] = usePostMessage(callback, postMessageConfig);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (getWindowOpener()) {
      sendMessage({
        state: POST_MESSAGE_STATE.CORRECT,
        event: POST_MESSAGE_EVENT.LOADED,
      });
      if (user) {
        sendMessage({
          state: POST_MESSAGE_STATE.CORRECT,
          event: POST_MESSAGE_EVENT.USER_LOGGED_IN,
        });
      }
    }
  }, [sendMessage, user]);

  return [sendMessage, listener];
};

export default usePostMessage;
