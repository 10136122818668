import iconAddWhite from '@assets/img/add_white.svg';
import iconArrowRight from '@assets/img/icon_arrow_right.svg';
import { WEEK_TITLE_TYPE } from '@store/calendar/calendarConsts';
import { selectIsActivityPlanReadOnly } from '@store/calendar/calendarSelectors';
import { addDays, format, isSameWeek } from 'date-fns';
import nlBE from 'date-fns/locale/nl-BE';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Activity from '../activity/Activity';

import './week.scss';

// TODO: move this logic to the selectors.
const getMonthDate = (startDate) => {
  const monthDate = format(startDate, 'MMMM', { locale: nlBE });
  return monthDate.charAt(0).toUpperCase() + monthDate.slice(1);
};
const getIsToday = (startDate) => {
  return (
    isSameWeek(new Date(), startDate, {
      weekStartsOn: 1,
    }) || false
  );
};
const getWeekDate = (week, display) => {
  const weekStartDate = week.dateRange.start;
  const weekEndDate = addDays(week.dateRange.end, -1);

  if (display === WEEK_TITLE_TYPE.FULL)
    return `${format(weekStartDate, 'd/M')} - ${format(weekEndDate, 'd/M')}`;
  if (display === WEEK_TITLE_TYPE.DATE) return format(weekStartDate, 'd/M');
  if (display === WEEK_TITLE_TYPE.NUMBER) return `Week $${week.id}`;

  return '';
};

const Week = ({ week, display, onMoveWeek, onSelectActivity, onAddActivity, onModifyActivity }) => {
  const weekDate = getWeekDate(week, display);
  const monthDate = useMemo(() => getMonthDate(week.dateRange.start), [week.dateRange.start]);
  const isToday = useMemo(() => getIsToday(week.dateRange.start), [week.dateRange.start]);
  const readOnly = useSelector(selectIsActivityPlanReadOnly);
  const isSelection = useSelector((state) => state.calendar.selectionMode);

  const moveWeekHandler = () => {
    if (readOnly) return;
    onMoveWeek?.(week);
  };

  const onModifyActivityHandler = (activity, plan) => {
    if (readOnly) return;
    onModifyActivity?.({ plan, week, activity });
  };

  const onAddActivityHandler = (activity, plan) => {
    if (readOnly) return;
    onAddActivity?.({ activity, plan, week });
  };

  if (!week) return null;

  return (
    <section className="week">
      {week.isFirstWeek && <span className="month-name">{monthDate}</span>}

      {isToday && <i id="today"></i>}
      <div className="week-date">{weekDate}</div>

      {week.activityPlans?.map((activityPlan, index) => (
        <div
          className={`activity-plan pg-${activityPlan.planGroup?.type} plan-${index}`}
          key={index}
        >
          {activityPlan.activityGroups?.map((activity, actIndex) => (
            <Activity
              key={activity.id || actIndex}
              activity={activity}
              onSelectActivity={onSelectActivity}
              onAddActivity={() => onAddActivityHandler(activity, activityPlan)}
              onModifyActivity={() => onModifyActivityHandler(activity, activityPlan)}
            />
          ))}

          {!activityPlan.activityGroups?.[0]?.empty && !readOnly && !isSelection && (
            <div className="buttons-row">
              {activityPlan.canMoveBack && (
                <button className={`move-week`} onClick={moveWeekHandler}>
                  <img src={iconArrowRight} />
                  <div className="title">Alle activiteiten vervroegen</div>
                </button>
              )}
              <button
                className={`add-week`}
                onClick={() => onAddActivityHandler(undefined, activityPlan)}
              >
                <img src={iconAddWhite} />
              </button>
            </div>
          )}
        </div>
      ))}

      {week.holidays && <div className="holidays">{week.holidays.title}</div>}
    </section>
  );
};

export default Week;
