import { getActivityPrintMonth } from '@utils/utils';
import './calendarPrint.scss';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const he = require('he');

const CalendarPrintActivity = ({ activity = {} }) => {
  const { title, children, period } = activity;

  return (
    <div className="activity-group">
      <header className="activity-header">
        <h2 className="title">{title}</h2>
        <span className="period">{getActivityPrintMonth(period)}</span>
      </header>
      <div className="activities">
        {children?.map((act) => (
          <div key={act.key} className="activity-row">
            <div className="period">
              <span>
                {format(new Date(act.period.startDate), 'dd/MM')} -{' '}
                {format(addDays(new Date(act.period.endDate), -1), 'dd/MM')}
              </span>
            </div>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: he.decode(act.description ?? '') }}
            ></div>
            <aside></aside>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarPrintActivity;
