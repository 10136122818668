/* eslint-disable @typescript-eslint/no-var-requires */
import add from 'date-fns/add';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import nlBE from 'date-fns/locale/nl-BE';
import { isEqual, isString, last, orderBy, uniqBy } from 'lodash-es';
import { shallowEqual } from 'react-redux';

import DOMPurify from 'dompurify';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import { SchoolyearVersion, VmClass } from '@store/userAndSchool/userAndSchoolTypes';
import sub from 'date-fns/sub';
import settings from '@config/settings';
import { creatorType } from '../constants/creatorType';
import levelType from '../constants/levelType';
import { CUSTOMCURRICULATYPES } from './curriculumHelper';
import { getGoalsWithChildren } from './nonDerivedHelper';
import { Schoolyear, SchoolyearBase } from '../types/schoolyear';
import { IApiResourceWithDates } from '../types/sriTypes';
import { ApiActivityPlan } from '../types/llinkidApiTypes';
import { getKeyFromHref } from './getKeyFromHref';

const addressUtils = require('@kathondvla/sri-client/address-utils');

export const conditionalLogTime = (label, printIfTimeAbove = 0) => {
  const start = performance.now();
  return () => {
    const time = performance.now() - start;
    if (time > printIfTimeAbove) {
      console.log(`${label} took more than ${printIfTimeAbove}ms: ${time}ms`);
    }
  };
};

export const formatDate = (date, formatString = 'dd/MM/yyyy') => {
  return format(date, formatString);
};

export const renameTo = (textToFind, rename, user) => {
  if (user) {
    const name = `${user.firstName} ${user.lastName}`;
    const reverseName = `${user.lastName} ${user.firstName}`;
    return textToFind.replace(name, rename).replace(reverseName, rename);
  }

  return textToFind;
};

export const printAddress = (address) => {
  return address ? addressUtils.printAddress(address) : '';
};

export const limitWords = (text, limit = 1) => {
  return text?.trim().split(' ').splice(0, limit).join(' ');
};

export const alwaysArray = (item) => {
  if (!item) return item;

  return Array.isArray(item) ? item : [item];
};

export const getFirstItemOfObject = (obj) => {
  if (!obj || typeof obj !== 'object') return null;

  const [key] = Object.keys(obj);
  return obj[key];
};

export const strip$$Properties = (object, excluded = ['$$meta']) => {
  const keys = Object.keys(object);
  const newObject = {};
  keys.forEach((key) => {
    const is$$Prop = key.startsWith('$$') && !excluded.includes(key);
    if (!is$$Prop) {
      newObject[key] = object[key];
    }
  });

  return newObject;
};

export const formatCalendarSelection = (calendar) => {
  const plans = calendar.plans?.map((p) => {
    const plan = strip$$Properties(p);
    return {
      href: p.$$meta.permalink,
      $$expanded: plan,
    };
  });

  return [
    {
      group: calendar.planGroup,
      plans,
    },
  ];
};

export const RESOURCES = {
  CUSTOM_CURRICULA: 'customCurricula',
  ANNOTATIONS: 'annotations',
  CUSTOM_ITEMS: 'customItems',
  CUSTOM_CURRICULA_GROUPS: 'customCurriculaGroups',
  ACTIVITY_PLANS: 'activityPlans',
  ACTIVITIES: 'activities',
} as const;

/**
 * we only add the leerjaar when more than one version is active
 * @param {*} param0
 * @returns
 */
const formatLeerjaar = ({ grade, versionStatus }) => {
  if (versionStatus && !versionStatus.basisOptie) {
    if (versionStatus.ending && grade === 1) return '2e leerjaar';
    if (versionStatus.starting && grade === 1) return '1e leerjaar';
    if (versionStatus.ending && grade === 2) return '4e leerjaar';
    if (versionStatus.starting && grade === 2) return '3e leerjaar';
  }
  return '';
};

export const formatVersionNumber = ({ version, grade, versionStatus }) => {
  if (!version) return null;
  let formattedVersion = '';

  if (!isString(version)) {
    const { major, minor, patch } = version;
    formattedVersion = `${major}.${minor}.${patch}`;
  } else {
    formattedVersion = version.replace('v', '');
  }

  const leerjaar = formatLeerjaar({ grade, versionStatus });

  let versionString = `v${formattedVersion}`.replace('.0.0', '');
  if (leerjaar) versionString += ` - ${leerjaar}`;

  return versionString;
};

export const convertArrayToObject = (array, key) => {
  const obj = {};
  array.forEach((item) => {
    obj[item[key]] = item;
  });
  return obj;
  // return array.reduce((obj, item) => ((obj[item[key]] = item), obj), {}); //oneliner that does the same. foreach is more readable.
};

export const mapRelationsForNextVersions = (relations) => {
  return new Map(relations.map((e) => [getKeyFromHref(e.to.href), e]));
};

export const getNextVersions = (nodeKey, relations, versions: any[] = []) => {
  const replaceRelation = relations.get(nodeKey);
  if (!replaceRelation) return versions;

  const { from } = replaceRelation;
  const key = from.href.split('/').pop();
  versions.push(key);

  return getNextVersions(key, relations, versions);
};

function generateVersions(schoolyears) {
  schoolyears.forEach((schoolyear, index) => {
    const year = schoolyear.startDate.getFullYear();
    const versions: SchoolyearVersion[] = [];

    if (index !== 0)
      // first year there is only one version.
      versions.push({
        startDate: new Date(Date.UTC(year - 1, 8, 1)),
        endDate: new Date(Date.UTC(year + 1, 7, 31)),
      } as SchoolyearVersion);

    versions.push({
      startDate: new Date(Date.UTC(year, 8, 1)),
      endDate: new Date(Date.UTC(year + 2, 7, 31)),
    } as SchoolyearVersion);

    versions.forEach((e) => {
      e.interval = { start: e.startDate, end: e.endDate };
      e.name = `Leerplannenversie ${e.startDate.getFullYear()} - ${e.endDate.getFullYear()}`;
      e.shortName = `${e.startDate.getFullYear()} - ${e.endDate.getFullYear()}`;
      e.key = `${e.startDate.getFullYear()}-${e.endDate.getFullYear()}`;
      e.accentColor = e.startDate.getFullYear() % 2 === 0;
      e.expired = isBefore(e.endDate, new Date());
    });

    schoolyear.versions = versions;
  });
}

/**
 * @param {Date} start
 * @param {Date} end
 */
export function generateSchoolYears(start: Date, end: Date): Schoolyear[] {
  let startYear;
  let endYear;
  if (start.getMonth() >= 8) {
    startYear = start.getFullYear();
  } else {
    startYear = start.getFullYear() - 1;
  }

  if (end.getMonth() < 8 || (end.getMonth() === 8 && end.getDate() === 1)) {
    // because 1st of sept is an enddate of schoolyear for sam...
    endYear = end.getFullYear();
  } else {
    endYear = end.getFullYear() + 1;
  }

  const schoolYears: SchoolyearBase[] = [];

  const initEarlyDate = add(new Date(), settings.hardcodings.initSchoolyearEarly);

  const earlyEndDateComponent = settings.hardcodings.endSchoolyearEarly;
  // console.log('settings', earlyEndDateComponent);

  for (let count = startYear; count < endYear; count += 1) {
    const value = `${count}-${count + 1}`;
    const startDate = new Date(Date.UTC(count, 8, 1));
    const endDate = new Date(Date.UTC(count + 1, 7, 31));

    const expiryDate = sub(endDate, earlyEndDateComponent);

    const sy = {
      name: `Schooljaar ${count} - ${count + 1}`,
      shortName: `${count} - ${count + 1}`,
      value,
      startDate,
      endDate,
      isoDates: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
      key: value,
      interval: { start: startDate, end: endDate },
      default: isWithinInterval(initEarlyDate, { start: startDate, end: endDate }), // move default schoolyear to next a few months in advance.
      expired: isBefore(expiryDate, new Date()),
    } as const;

    schoolYears.push(sy);
  }
  generateVersions(schoolYears);
  if (!schoolYears.some((e) => e.default)) {
    const lastSy = last(schoolYears);
    if (lastSy) {
      lastSy.default = true;
    }
  }

  return schoolYears;
}

export function getSchoolyears(
  schoolStartDate?: string,
  schoolEndDate?: string | null
): Schoolyear[] {
  const oneYearAhead = new Date();
  oneYearAhead.setFullYear(oneYearAhead.getFullYear() + 2);

  const endDate = schoolEndDate ? new Date(schoolEndDate) : oneYearAhead;
  const startDate =
    schoolStartDate && new Date(schoolStartDate) > new Date(2019, 8, 1)
      ? new Date(schoolStartDate)
      : new Date(2019, 8, 1); // js starts month at 0....... .. .. ...

  let years = generateSchoolYears(startDate, endDate);

  years = years.map((e) => {
    const initialized = e.value <= settings.lastInitializedSchoolYear;
    return { ...e, initialized };
  });

  return years;
}

export function getPreviousSchoolyear(schoolyearKey) {
  const schoolyears = getSchoolyears();
  const index = schoolyears.findIndex((z) => z.key === schoolyearKey);
  return schoolyears[index - 1];
}

export function validityPeriodToString(validityPeriod) {
  const start = new Date(validityPeriod.startDate); // we assume start is always 1st september of 20XX
  let validity = `vanaf ${start.getFullYear()} - ${start.getFullYear() + 1}`;
  if (validityPeriod.endDate) {
    const end = new Date(validityPeriod.endDate);
    validity = `${validity} tot en met ${end.getFullYear() - 1} - ${end.getFullYear()}`;
  }
  return validity;
}

export function cloneWithJson(obj) {
  if (!obj) {
    return obj;
  }
  return JSON.parse(JSON.stringify(obj));
}

export function translatePointer(pointer, educationalPointers) {
  let selected = false;

  if (educationalPointers) {
    selected = !!educationalPointers.find(
      (edPointer) => edPointer.href === pointer.$$meta.permalink
    );
  }

  return {
    pointer,
    selected,
  };
}

export const mapTo$$Expanded = (list) => {
  return list.map((e) => ({ href: e.$$meta.permalink, $$expanded: e }));
};

export function getHrefSet(arrayOfHrefs) {
  return new Set(arrayOfHrefs.map((e) => e.href));
}

export function getPlansForUsers(
  activityPlans: ApiActivityPlan[],
  planCreatorsAndObserversMap,
  userHrefs: string[],
  schoolyear: Schoolyear,
  includeObservers = false
) {
  let plans = activityPlans.filter((p) =>
    userHrefs.some(
      (u) =>
        planCreatorsAndObserversMap[p.key].$$creatorsSet.has(u) ||
        (includeObservers && planCreatorsAndObserversMap[p.key].$$observerSet.has(u))
    )
  );
  if (schoolyear) {
    plans = plans.filter((e) => e.issued.startDate === schoolyear.isoDates.startDate);
  }
  return plans;
}

export function getCurriculaForPlan(allCurrs, plan) {
  if (!plan) return null;
  const planCurSet = getHrefSet(plan.curricula);
  return allCurrs?.filter((c) => planCurSet.has(c.customCurriculum || c.customCurriculaGroup));
}

// @ts-expect-error not used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const plainEqualWithLog = (functionName) => {
  return (a, b) => {
    const logTimeEnd = conditionalLogTime(`${functionName} plainEqual`, 10);
    const res = a === b;
    logTimeEnd();
    console.log(`${functionName} changed: ${!res}`);
    if (!res) {
      // console.log(diffString(a, b));
      // console.log('before', a, 'after', b);
    }
    return res;
  };
};

// @ts-expect-error not used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const deepEqualWithLog = (functionName) => {
  return (a, b) => {
    const logTimeEnd = conditionalLogTime(`${functionName} deepEqual`, 10);
    const res = isEqual(a, b);
    logTimeEnd();
    console.log(`${functionName} changed: ${!res}`);
    if (!res) {
      // console.log(diffString(a, b));
      // console.log('before', a, 'after', b);
    }
    return res;
  };
};

// @ts-expect-error not used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const shallowEqualWithLog = (functionName) => {
  return (a, b) => {
    const logTimeEnd = conditionalLogTime(`${functionName} shallowEqual`, 10);
    const res = shallowEqual(a, b);
    logTimeEnd();
    console.log(`${functionName} changed: ${!res}`);
    return res;
  };
};

export function renameChildrenToItems(curr) {
  let newCur = curr;
  if (curr) {
    newCur = {
      ...curr,
      items: curr.children && curr.children.map((e) => renameChildrenToItems(e)),
    };
    delete newCur.children;
  }
  return newCur;
}

export function getViewModelEntities(vm) {
  const entities = getGoalsWithChildren(vm);

  return entities.filter((e) => e);
}

export function deepFreeze(object) {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self

  propNames.forEach((name) => {
    const value = object[name];
    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  });

  return Object.freeze(object);
}

function addSrc({ list, property }) {
  return list.map((item) => {
    return {
      ...item,
      src: levelType.properties[item[property]].src,
    };
  });
}

function sortCreators(options) {
  options.forEach((o) => {
    let optionCreatorType = 3;
    if (o.creatorType === creatorType.school) optionCreatorType = 1;
    else if (o.creatorType === creatorType.team) optionCreatorType = 2;
    o.priority = optionCreatorType;
  });

  return orderBy(options, ['priority', '$$displayName']);
}

export const getAllCreatorsWithIcon = (allCreators) => {
  const allOrgsWithSrc = addSrc({ list: allCreators, property: 'creatorType' });

  return sortCreators(allOrgsWithSrc);
};

export const setToDateAfterFromDate = (from, to, options) => {
  let nextTo = to;
  const fromIndex = options.startDates.findIndex((e) => e.value === from);
  const toIndex = options.endDates.findIndex((e) => e.value === to);
  const periodToOptions = options.endDates.slice(fromIndex);

  if (toIndex < fromIndex) {
    const nextToIndex = fromIndex + 1 >= options.startDates.length ? fromIndex : fromIndex + 1;
    nextTo = options.endDates[nextToIndex].value;
  }

  return { to: nextTo, toOptions: periodToOptions };
};

export const getActivityPrintMonth = (period) => {
  if (!period) return '';

  const startMonth = format(new Date(period.startDate), 'MMMM', { locale: nlBE });
  const endMonth = format(new Date(period.endDate), 'MMMM', { locale: nlBE });

  return startMonth !== endMonth ? `${startMonth} - ${endMonth}` : startMonth;
};

export const formatCalendarPrintOptionWeeks = (weeks: any[] = []) => {
  const startDates: Array<{
    value: string;
    name: string;
  }> = [];
  const endDates: Array<{
    value: string;
    name: string;
  }> = [];
  const formatOptions = (date) => ({
    value: date.toISOString(),
    name: format(date, 'dd/MM'),
  });

  weeks.forEach((w) => {
    startDates.push(formatOptions(w.startDate));
    endDates.push(formatOptions(addDays(w.endDate, -1)));
  });

  return { startDates, endDates };
};

export function getNonDerivedRoot(allCustomCurricula, customCur) {
  if (
    customCur.type === CUSTOMCURRICULATYPES.adaptation &&
    !customCur.source.href.startsWith('/content')
  ) {
    return allCustomCurricula.find((e) => e.key === last(customCur.source.href.split('/')));
  }
  if (customCur.type === CUSTOMCURRICULATYPES.custom) {
    return customCur;
  }
  return undefined;
}

// TODO: since this needs all customcurricula as an input, it probably should be a selector.
export function getNonDerivedGroupKey(allCustomCurricula, customCurr) {
  const nonDerived = getNonDerivedRoot(allCustomCurricula, customCurr);

  return nonDerived?.customCurriculaGroup?.href ?? nonDerived?.$$meta.permalink;
}

export function getClassesOptions(activeClasses: VmClass[], exclude: string[] = []) {
  return activeClasses
    .filter((c) => !exclude.includes(c.key))
    .sort((a, b) => (a.$$displayName > b.$$displayName ? 1 : -1));
}

export function groupTeachersValueByLevel(selected) {
  if (!selected || selected.length === 0) return { values: selected, regrouped: false };

  const addedItem = selected[selected.length - 1];
  let removed: any[] = [];
  let groupedValues = selected;
  let alert;

  if (addedItem.creatorType === 'SCHOOL') {
    removed = selected.filter((t) => t.key !== addedItem.key);
    groupedValues = selected.filter((t) => t.key === addedItem.key);

    if (removed.length) {
      alert = {
        key: 'teacher-selection-changed-school-alert',
        title: 'Selectie aangepast',
        msg: 'Alle leerkrachten zijn verwijderd omdat zij zich in de school bevinden.',
        type: 'info',
        showClose: true,
        delay: 8000,
      };
    }
  } else if (addedItem?.teachers?.length > 0) {
    const tset = new Set(addedItem.teachers.map((e) => e.href));
    groupedValues = selected.filter((c) => !tset.has(c.href));
    removed = selected.filter((c) => tset.has(c.href));

    if (removed.length) {
      alert = {
        key: 'teacher-selection-changed-team-alert',
        title: 'Selectie aangepast',
        msg: `Leerkrachten ${removed
          .map((c) => c.$$displayName)
          .join(', ')} zijn verwijderd omdat zij zich in het team bevinden.`,
        type: 'info',
        showClose: true,
        delay: 8000,
      };
    }
  }

  return { values: groupedValues, regrouped: !!removed.length, alert };
}

export function groupCurriculaValuesByGrade(selectedCurricula) {
  const lastItem = selectedCurricula[selectedCurricula.length - 1];
  const removedCurr: any[] = [];
  const { grade } = lastItem;
  let alert;
  const groupedValues = selectedCurricula.filter((item) => {
    if (item.grade !== grade) {
      removedCurr.push(item);
      return false;
    }

    return true;
  });

  if (removedCurr.length) {
    const msg = `Leerplannen ${removedCurr
      .map((e) => e.name)
      .join(', ')} zijn verwijderd uit de selectie omdat deze van een andere graad zijn.`;
    const title = 'Andere graad';
    alert = {
      key: 'mixed-grade-alert',
      title,
      msg,
      type: 'info',
      showClose: true,
      delay: 8000,
    };
  }

  return { values: groupedValues, alert };
}

export function isValidPopupDate({ startDate, endDate }) {
  let valid = true;
  const today = new Date();
  if (startDate && startDate !== '') {
    valid = isSameDay(new Date(startDate), today) || isBefore(new Date(startDate), today);
  }
  if (valid && endDate && endDate !== '') {
    valid = isAfter(new Date(endDate), today);
  }

  return valid;
}

export function isActivityplanInSchoolyear(start, end, schoolyear) {
  return (
    isWithinInterval(new Date(start), schoolyear.interval) &&
    isWithinInterval(new Date(end), schoolyear.interval)
  );
}

export function isInVersion(start, end, version) {
  return (
    isWithinInterval(new Date(start), version.interval) &&
    (!end || isWithinInterval(new Date(end), version.interval))
  );
}

export function getParamsFromCurriculum(curriculum, schoolyear) {
  return {
    curriculumKey: curriculum.id,
    custids: curriculum.custid ? curriculum.custid.split(',') : undefined,
    setid: curriculum.setid,
    studids: curriculum.type !== 'PLAN' ? curriculum.studid.split(',') : null,
    schoolyear,
  };
}

export function removeLinks(text) {
  return text.replace(/<\/?a[^>]*>?/gm, '');
}

/**
 * objectMap lets you map an object to a new object, as you would with an array.map
 * @param {*} obj
 * @param {*} fn
 */
export const objectMap = (obj, fn) => {
  return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
};

export function getInitials(user) {
  const initials = {
    first: '',
    second: '',
  };
  if (user) {
    if (user.firstName) initials.first = user.firstName.toUpperCase().charAt(0);
    if (user.lastName) initials.second = user.lastName.toUpperCase().charAt(0);
  }

  return initials.first + initials.second;
}

export async function sleep(milliseconds) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

function getReadOrderFromRelation(item) {
  if (item.$$relationsFrom) {
    const relation = item.$$relationsFrom.find((e) => e.relationtype === 'IS_PART_OF');
    if (relation) {
      return relation.readorder;
    }
  }
  return undefined;
}

export function getReadOrder(item) {
  return item.$$readOrder || item.readOrder || getReadOrderFromRelation(item);
}

/**
 * Calculates the readOrder number, given the above and below object, and an (optional) count, stating how many items are inserted and need a result.
 *
 */
export function calculateReadOrderSet(above, below, count) {
  // /if item is the first item. aboveRO will be undefined.
  // /if item is the last item, belowRO will be undefined.
  const aboveRO = above && (above.$$readOrder || above.readOrder);
  const belowRO = below && (below.$$readOrder || below.readOrder);

  const base = Math.ceil(Math.log2(count + 1)); // /get the closest power of 2 (so for 10, we get 4, 2^4 = 16.)
  let delta;
  let startingPoint;

  if (aboveRO !== undefined && belowRO !== undefined) {
    // /between two items, split the difference by the nearest power of two (for better float usage) so 10 items, use 1/16th increments per item.
    delta = (belowRO - aboveRO) / 2 ** base;
    startingPoint = aboveRO;
  } else if (aboveRO !== undefined) {
    // /last item, add after everything
    delta = +1;
    startingPoint = aboveRO;
  } else if (belowRO !== undefined) {
    // first item, add before everything
    delta = -1;
    startingPoint = belowRO;
  } else {
    // /nothing is set. this is a new item
    delta = 1;
    startingPoint = 0;
  }

  const response: number[] = [];
  for (let i = 1; i <= count; i += 1) {
    response.push(startingPoint + delta * i);
  }

  if (delta < 0) {
    // /if the delta is negative. we need to reverse the order. otherwise the items will be ordered in reverse.
    response.reverse();
  }

  return response;
}

export function calculateReadOrder(above, below) {
  return calculateReadOrderSet(above, below, 1)[0];
}

export function isCustomCurriculaSet(href) {
  return href.indexOf('customcurriculagroups') !== -1;
}

export function distinctBy(array, property) {
  return uniqBy(array, property);
}

function makeid(prepend, length) {
  let result = prepend || '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getVskoReqHeader(options = {}) {
  const headers: any = 'headers' in options ? options.headers : {};
  headers['vsko-req-id'] = makeid('LLINKID-', 8);
  return Object.assign(options, { headers });
}

export function toggleArrayItem({
  list = [],
  item,
  addItem = true,
}: {
  list: string[];
  item: any;
  addItem: boolean;
}) {
  const setList = new Set(list);

  if (addItem) {
    setList.add(item);
  } else {
    setList.delete(item);
  }

  return [...setList];
}

export function stripHTML(html) {
  return DOMPurify.sanitize(html?.trim(), { ALLOWED_TAGS: [] });
}

export function isIframe() {
  return window.location !== window.parent.location;
}

export function filterResourcesActiveInSchoolyear<T extends IApiResourceWithDates>(
  resources: T[],
  schoolyear: Schoolyear
): T[] {
  const schoolYearStart = schoolyear.startDate;
  const schoolYearEnd = schoolyear.endDate;

  return resources.filter((resource) => {
    const resourceStart = new Date(resource.startDate);
    // If resourceEnd is undefined or null, use a far future date as the end date
    const resourceEnd = resource.endDate ? new Date(resource.endDate) : new Date('2099-12-31');

    return areIntervalsOverlapping(
      { start: resourceStart, end: resourceEnd },
      { start: schoolYearStart, end: schoolYearEnd }
    );
  });
}
