import ActivityGoalSelector from '@UI/activityGoalSelector/ActivityGoalSelector';
import AddAttachmentForm from '@UI/addAttachmentForm/AddAttachmentForm';
import Attachment from '@UI/attachment/Attachment';
import { TextEditor } from '@UI/index';
import commonUtils from '@kathondvla/sri-client/common-utils';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton } from '@mui/material';
import './activityChildEditor.scss';

const ActivityChildEditor = ({
  activity,
  goalOptions,
  curricula,
  renderTo,
  onLeerplanSelection,
  occurencesPerGoal,
  onChange,
  onRemove,
}) => {
  const [showGoals, setShowGoals] = useState(!!activity.goals?.length);
  const [showAttForm, setshowAttForm] = useState(false);
  const [attachments, setAttachments] = useState(activity.attachments ?? []);
  const activityRef = useRef();
  const period = useMemo(
    () =>
      activity?.period
        ? `${format(parseISO(activity.period.startDate), 'dd/MM')} - ${format(
            parseISO(activity.period.endDate),
            'dd/MM'
          )}`
        : '05/09 - 10/09',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activity?.period.startDate]
  );

  const changeGoals = (goals) => {
    onChange?.({
      ...activity,
      goals,
    });
  };

  const titleChangeHandler = (text) => {
    onChange?.({
      ...activity,
      description: text,
    });
  };

  const removeAttachment = (att) => {
    const updatedAtt = attachments.map((e) => {
      if (e.href === att.href)
        return {
          ...e,
          deleted: true,
        };

      return e;
    });

    setAttachments(updatedAtt);
    onChange?.({ ...activity, attachments: updatedAtt });
  };

  const onAddAttHandler = (att) => {
    const wrappedAtt = { href: `temp-${commonUtils.generateUUID()}`, $$expanded: att };
    const updatedAtt = [...attachments, wrappedAtt];
    setshowAttForm(false);
    setAttachments(updatedAtt);
    onChange?.({ ...activity, attachments: updatedAtt });
  };

  useLayoutEffect(() => {
    if (renderTo) {
      activityRef.current?.scrollIntoView({ behaviour: 'smooth', block: 'center' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="activity-child-editor" ref={activityRef}>
      <div className="date-info">
        <span className="dates">{period}</span>
        <div className="buttons">
          <Button startIcon={<DeleteIcon />} onClick={() => onRemove?.(activity)}>
            Verwijderen
          </Button>
          <Button startIcon={<AddBoxIcon />} onClick={() => setShowGoals(!showGoals)}>
            Bijkomende leerplandoelen
          </Button>
        </div>
      </div>

      {showGoals && (
        <div className="goals">
          <ActivityGoalSelector
            goals={activity.goals}
            occurencesPerGoal={occurencesPerGoal}
            options={goalOptions}
            curricula={curricula}
            onChange={changeGoals}
            onLeerplanSelection={(cur) => onLeerplanSelection(activity, cur, 'child')}
          />
        </div>
      )}

      <div className="info">
        <div className="description">
          <header>Inhoud</header>
          <div className="editor">
            <TextEditor value={activity.description} onChange={titleChangeHandler}></TextEditor>
          </div>
        </div>
        <div className="attachments">
          <header>
            <span>Bijlage(n) & links</span>
            <Button startIcon={<AddBoxIcon />} onClick={() => setshowAttForm(!showAttForm)}>
              Toevoegen
            </Button>
          </header>
          {showAttForm && (
            <AddAttachmentForm onAdd={onAddAttHandler} onCancel={() => setshowAttForm(false)} />
          )}
          <div className="list">
            {attachments
              .filter((att) => !att.deleted)
              .map((att) => (
                <div key={att.href} className="attachment-wrapper">
                  <Attachment item={att.$$expanded} />
                  <IconButton color="primary" onClick={() => removeAttachment(att)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityChildEditor;
